import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="" mdxType="Columns">
      <p>{`Hitabis bietet seit 2004 Dienstleistungen und Know-how rund um Konzeption und Entwicklung kundenspezifischer IT-Systeme. Schon seit 2012 sind wir offizieller Oracle-Partner. Hitabis betreut deutschlandweit Unternehmen bei der Realisierung hoch performanter Datenbanken, Enterprise Content Management Systemen und bei der Integration von IT-Systemen mit technischen Anlagen und Maschinen.`}</p>
      <p>{`Um Ihnen möglichst viele dieser Leistungen aus einer Hand anbieten zu können, setzen wir auf Unabhängigkeit und Teamwork. Flache Hierarchien, hohe Flexibilität und ein gelebter Teamgeist stehen hinter den Leistungen von Hitabis. Unsere IT-Spezialisten sind eine schlagkräftige Mannschaft, die auch für außergewöhnliche Problemstellungen schnell und effektiv überzeugende Lösungen findet und umsetzt. Hitabis ist Mitglied in vielen Organisationen wie der Bitkom, dem ASQF und der DOAG. Als mittelständisches Unternehmen verbinden wir Innovationsfreude mit Beständigkeit und sind dadurch ein zuverlässiger Partner für unsere Kunden. Dies beweist sich auch durch das ständige Wachstum unseres Teams.`}</p>
      <p>{`Durch unsere langjährige Erfahrung und eine enge Verzahnung von Analyse, Konzeption, Beratung, Projektmanagement und Anwendungsentwicklung helfen wir Ihnen dabei, Ihre Projekte erfolgreich umzusetzen. Umfassendes Know-how in aktuellen Technologien und Methoden wie z.B. agile Entwicklung, DevOps mit Docker, Oracle, NoSQL-DB, Java EE und App-Entwicklung zeichnen uns aus.`}</p>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      